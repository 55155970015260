import React from "react";
import PropTypes from "prop-types";
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  Pagination,
  ReferenceField,
  SelectInput,
  translate,
} from "react-admin";
import paginationOptions from "../../config/PaginationOptions";
import NotificationTypes from "../../config/NotificationTypes";
import DateField from "../customFields/DateField";
import NotificationReadButton from "./NotificationReadButton";
import NotificationBody from "./NotificationBody";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";

const _Filters = ({ translate, ...props }) => (
  <Filter {...props}>
    <NullableBooleanInput source="read" label={translate(getKey("readAt", resources.NOTIFICATIONS))} alwaysOn />
    <SelectInput
      source="notificationTypeValue"
      label={translate(getKey("notificationType", resources.NOTIFICATIONS))}
      choices={Object.values(NotificationTypes)}
      optionText="translation"
      optionValue="key"
      alwaysOn
    />
  </Filter>
);
const Filters = translate(_Filters);

const NotificationsList = ({ translate, permissions, ...props }) => (
  <List
    {...props}
    actions={null}
    bulkActionButtons={false}
    pagination={<Pagination {...paginationOptions} />}
    filters={<Filters />}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ read: false }}
  >
    <Datagrid>
      <DateField source="createdAt" displayFormat="DD. MMMM YYYY HH:mm" />
      <FunctionField
        source="notificationType"
        render={({ notificationType }) =>
          notificationType && translate(NotificationTypes[notificationType].translation)
        }
      />
      <FunctionField
        source="body"
        sortable={false}
        render={notification =>
          notification.shipmentId ? (
            <ReferenceField record={notification} source="shipment" reference="shipments" link={false}>
              <FunctionField
                render={shipment => <NotificationBody notification={notification} shipment={shipment} />}
              />
            </ReferenceField>
          ) : (
            // Wenn es keine shipmentId gibt, rendern Sie NotificationBody ohne shipment
            <NotificationBody notification={notification} />
          )
        }
      />
      <NotificationReadButton
        source="readAt"
        displayFormat="DD. MMMM YYYY HH:mm"
        textAlign="right"
        buttonLabel="Gelesen"
      />
    </Datagrid>
  </List>
);

NotificationsList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(NotificationsList);
