import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  minValue,
  number,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SimpleForm,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import ContractorTypes from "../../config/ContractorTypes";

const requiredValidation = required();
const productionTimeValidation = [required(), number(), minValue(0)];

const SupportedMaterialCreate = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list" variant="standard" margin="normal">
        <ReferenceArrayInput
          source="contractor"
          reference={resources.CONTRACTORS}
          validate={requiredValidation}
          filter={{
            contractorType: [ContractorTypes.DUMMY.key, ContractorTypes.EXTERNAL.key, ContractorTypes.INTERNAL.key],
          }}
          perPage={1000}
          fullWidth
        >
          <SelectArrayInput optionText="contractorName" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="material"
          reference={resources.MATERIALS}
          validate={requiredValidation}
          perPage={1000}
          filterToQuery={searchText => ({ autocomplete: searchText })}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText={({ name, methodKey, methodName, method, color, layer_thickness }) =>
              `${methodKey || methodName || method || "?"} (${name +
                (color ? " " + color : "") +
                (layer_thickness ? " " + layer_thickness : "")})`
            }
          />
        </ReferenceArrayInput>
        <ReferenceInput
          source="priceFormula"
          reference={resources.FORMULAS}
          perPage={1000}
          filter={{ type: "MODEL_PRICE_FORMULA" }}
          filterToQuery={searchText => ({ name: searchText })}
          validate={requiredValidation}
          fullWidth
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <NumberInput source="productionTime" validate={productionTimeValidation} fullWidth />
      </SimpleForm>
    </Create>
  </div>
);

export default translate(SupportedMaterialCreate);
