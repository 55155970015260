import React from "react";
import {
  AutocompleteInput,
  Edit,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import Title from "../customFields/Title";

const requiredValidation = required();
const productionTimeValidation = [required(), number(), minValue(0)];

const SupportedMaterialEdit = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Edit title={<Title resource={resources.SUPPORTED_MATERIALS} />} undoable={false} {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list" variant="standard" margin="normal">
        <ReferenceInput source="contractor" reference={resources.CONTRACTORS} validate={requiredValidation}>
          <SelectInput optionText="contractorName" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="material"
          reference={resources.MATERIALS}
          perPage={1000}
          filterToQuery={searchText => ({ autocomplete: searchText })}
          validate={requiredValidation}
        >
          <AutocompleteInput
            optionText={({ name, methodKey, methodName, method, color, layer_thickness }) =>
              `${methodKey || methodName || method || "?"} (${name +
                (color ? " " + color : "") +
                (layer_thickness ? " " + layer_thickness : "")})`
            }
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceInput
          source="priceFormula"
          reference={resources.FORMULAS}
          perPage={1000}
          filter={{ type: "MODEL_PRICE_FORMULA" }}
          filterToQuery={searchText => ({ name: searchText })}
          validate={requiredValidation}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <NumberInput source="productionTime" validate={productionTimeValidation} />
      </SimpleForm>
    </Edit>
  </div>
);

export default translate(SupportedMaterialEdit);
