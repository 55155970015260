import React, { Fragment } from "react";
import { FunctionField, ReferenceManyField, ShowButton, translate } from "react-admin";
import resources from "../../config/resources";
import PlainShow from "../PlainShow";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getKey } from "../../lib/i18nUtils";
import PriceField from "../customFields/PriceField";
import SendOfferButton from "./SendOfferButton";
import RohStatus from "../../config/RohStatus";
import ModelDatagrid from "../models/ModelDatagrid";
import DocumentsBox from "../documents/DocumentsBox";
import CommentBox from "../comments/CommentBox";
import ReferenceManyMapper from "../ReferenceManyMapper";

const styles = theme => ({
  infoBox: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  modelsBox: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  table: {
    overflowX: "auto",
  },
  inlineButton: {
    display: "inline",
    marginBottom: 0,
    marginTop: "-" + theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
  },
  negMarginButton: {
    marginTop: `-${theme.spacing(2)}px`,
    marginBottom: `-${theme.spacing(2)}px`,
  },
});

const ShowRow = ({ translate, ...props }) => (
  <div style={{ width: "100%", textAlign: "right" }}>
    <ShowButton {...props} basePath="jobs" label={translate(getKey("showContractorView", resources.JOBS))} />
  </div>
);

const JobPanel = ({ translate, classes, permissions, ...props }) => (
  <PlainShow {...props} title="" location="">
    <Fragment>
      <DocumentsBox {...props} permissions={permissions} style={{ marginLeft: -8 }} target="job#id" />
      <Paper className={classes.modelsBox} elevation={2}>
        <ReferenceManyField target="job#id" {...props} perPage={1000} reference={resources.MODELS} source="__id">
          <ModelDatagrid {...props} base={props.basePath} permissions={permissions} job={props.record} />
        </ReferenceManyField>
        <ShowRow {...props} translate={translate} />
      </Paper>
      <Paper className={classes.modelsBox} style={{ padding: 8 }} elevation={2}>
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          {translate(getKey("price", resources.JOBS))}
        </Typography>
        {props.record.minimumPrice && (
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body2">{translate(getKey("minimumPrice", resources.JOBS))}:</Typography>
            </Grid>
            <Grid item xs={2}>
              <PriceField {...props} source="minimumPrice" />
            </Grid>
          </Grid>
        )}
        {props.record.partsPrice && (
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body2">{translate(getKey("partsPrice", resources.JOBS))}:</Typography>
            </Grid>
            <Grid item xs={2}>
              <PriceField {...props} source="partsPrice" />
            </Grid>
          </Grid>
        )}
        {props.record.transportPrice && (
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body2">{translate(getKey("transportPrice", resources.JOBS))}:</Typography>
            </Grid>
            <Grid item xs={2}>
              <PriceField {...props} source="transportPrice" />
            </Grid>
          </Grid>
        )}
        {props.record.discount && (
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body2">{translate(getKey("discount", resources.JOBS))}:</Typography>
            </Grid>
            <Grid item xs={2}>
              <PriceField {...props} source="discount" />
            </Grid>
          </Grid>
        )}
        {props.record.price && (
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body1">{translate(getKey("totalPrice", resources.JOBS))}:</Typography>
            </Grid>
            <Grid item xs={2}>
              <PriceField {...props} source="price" />
            </Grid>
          </Grid>
        )}
        {props.record.status === RohStatus.NEW.apiValue && (
          <ReferenceManyField {...props} record={props.record} reference={resources.MODELS} target="job#id">
            <ReferenceManyMapper>
              {({ data }) => (
                <SendOfferButton
                  {...props}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  models={data}
                />
              )}
            </ReferenceManyMapper>
          </ReferenceManyField>
        )}
      </Paper>
      <FunctionField
        {...props}
        addLabel={false}
        render={record =>
          permissions &&
          permissions.authorities.includes("ROLE_SALES") && (
            <CommentBox {...props} record={record} jobId={record.id} permissions={permissions} />
          )
        }
      />
    </Fragment>
  </PlainShow>
);

export default withStyles(styles)(translate(JobPanel));
